import { DOCUMENT_NAME_TO_ID } from 'global/SharedDocumentFields';
import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';

export const shouldNeSecondPageUploadSectionRender = (
  sectionId,
  triggerIds,
  branchSelectionValues
) => {
  const documentsToHideWhenHilfsbetrieb = [DOCUMENT_NAME_TO_ID.craftCard];
  if (
    documentsToHideWhenHilfsbetrieb.includes(sectionId) &&
    isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)
  ) {
    return false;
  }
  return triggerIds.includes(sectionId);
};
