export const bagGasMainInstallerData = {
  anredename: null,
  ausw_gas_datum: '2023-09-30',
  ausw_gas_gueltig: '2024-09-28',
  ausw_probe_gas: 0,
  ausw_probe_strom: 0,
  ausw_probe_wasser: 0,
  ausw_strom_datum: '2023-09-28',
  ausw_strom_gueltig: '2028-09-28',
  ausw_strom_matrizennr: null,
  ausw_wasser_datum: null,
  ausw_wasser_gueltig: null,
  ausweisnr_fremd_gas: 'xyz123',
  ausweisnr_fremd_strom: null,
  ausweisnr_fremd_wasser: null,
  b_anrede: null,
  b_betriebsart_gas: {
    id: 27,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '20288,2240',
  },
  b_betriebsart_strom: {
    id: 5,
    name: 'Hilfsbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_wasser: null,
  b_briefanrede: {
    id: 3,
    name: 'Sehr geehrte Damen und Herren',
    geloescht: 0,
  },
  b_dezentrale_einheit_gas: null,
  b_dezentrale_einheit_strom: null,
  b_dezentrale_einheit_wasser: null,
  b_eintragung_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_gas_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_wasser_als: null,
  b_hwkbereich: {
    id: 3,
    name: 'Schwaben',
    geloescht: 0,
  },
  b_netzbetreiber_gas: {
    id: 1036,
    name: 'SWM Infrastruktur GmbH',
    geloescht: 0,
    ort: 'München',
    plz: '80992',
    strasse: 'Emmy-Noether-Str. 2',
    netzbetreibernummer: 7248,
    eintragungsart: 2,
  },
  b_netzbetreiber_strom: {
    id: 33,
    name: 'Bayernwerk Netz GmbH',
    geloescht: 0,
    ort: 'Regensburg',
    plz: '93049',
    strasse: 'Heinkelstr. 1',
    netzbetreibernummer: 10681,
    eintragungsart: 1,
  },
  b_netzbetreiber_wasser: null,
  b_standort: null,
  bemerkung: null,
  besichtigung_ausschuss: null,
  besichtigung_datum: null,
  besichtigung_eon: null,
  betriebshaftpflicht: 0,
  buero_hausnummer: '1',
  buero_land: null,
  buero_ort: 'Testort',
  buero_ortsteil: null,
  buero_plz: '11111',
  buero_strasse: 'Teststraße',
  communication_email: null,
  eintragungsart_erg_gas: 'Darf nur Heizkessel installieren.',
  eintragungsart_erg_strom: 'Darf nur Elektroherde anschließen.',
  eintragungsart_erg_wasser: null,
  eintragungsart_gas: 1,
  eintragungsart_strom: 1,
  eintragungsart_wasser: null,
  email: 'petertestp15@gmail.com',
  ersteintragung: '2023-09-20',
  ersteintragung_gas: '2023-09-26',
  ersteintragung_wasser: null,
  fax: null,
  fax_werkstatt: null,
  firmeninhaber: null,
  firmenname1: 'BAG Ciam eFix 2',
  firmenname2: 'Test',
  firmenname3: 'test2',
  geloescht_am: null,
  geloescht_gas_am: null,
  geloescht_wasser_am: null,
  gewerbeanmeldung_datum: '2023-06-15',
  gewerbeanmeldung_ort: 'Augsburg',
  hrabnummer: 'HRA 007',
  hwkdatum: '2023-09-04',
  hwknummer: '98031',
  id: 100000307515,
  importiert: null,
  in_verlaengerung: false,
  instv_gas_am: null,
  instv_wasser_am: null,
  lfdnr: null,
  musteranlage_gas: null,
  netzportal: 1,
  pflegende_bu: '20288',
  referenznummer_gas: null,
  referenznummer_strom: null,
  referenznummer_wasser: null,
  register_letzte_aenderung: '2023-08-03',
  registergericht: 'Augsburg',
  richtlinien: null,
  richtlinien_version: null,
  status_gas: 2,
  status_gas_automatic: 1,
  status_strom: 1,
  status_strom_automatic: 0,
  status_wasser: null,
  status_wasser_automatic: null,
  telefon_geschaeft: '123',
  telefon_mobil: null,
  telefon_werkstatt: null,
  werkstatt_hausnummer: null,
  werkstatt_land: null,
  werkstatt_ort: null,
  werkstatt_ortsteil: null,
  werkstatt_plz: null,
  werkstatt_strasse: null,
  zaehlermontage_gas: '00',
  zaehlermontage_strom: '00',
  zu_adressveroeff: 'Ja',
  zu_gasinfo: 'Ja',
  zu_infoversand: 'Nein',
  zu_kommunikation_mail: 'Ja',
  zu_netzanschlussportal: 'Ja',
  zu_technikinfo: 'Ja',
};
export const bagEnergyMainInstallerData = {
  anredename: null,
  ausw_gas_datum: '2023-09-30',
  ausw_gas_gueltig: '2024-09-28',
  ausw_probe_gas: 0,
  ausw_probe_strom: 0,
  ausw_probe_wasser: 0,
  ausw_strom_datum: '2023-09-28',
  ausw_strom_gueltig: '2024-11-28',
  ausw_strom_matrizennr: null,
  ausw_wasser_datum: null,
  ausw_wasser_gueltig: null,
  ausweisnr_fremd_gas: 'xyz123',
  ausweisnr_fremd_strom: null,
  ausweisnr_fremd_wasser: null,
  b_anrede: null,
  b_betriebsart_gas: {
    id: 27,
    name: 'Hilfsbetrieb',
    geloescht: 0,
    pflegende_bu: '20288,2240',
  },
  b_betriebsart_strom: {
    id: 5,
    name: 'Hilfsbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_wasser: null,
  b_briefanrede: {
    id: 3,
    name: 'Sehr geehrte Damen und Herren',
    geloescht: 0,
  },
  b_dezentrale_einheit_gas: null,
  b_dezentrale_einheit_strom: null,
  b_dezentrale_einheit_wasser: null,
  b_eintragung_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_gas_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_wasser_als: null,
  b_hwkbereich: {
    id: 3,
    name: 'Schwaben',
    geloescht: 0,
  },
  b_netzbetreiber_gas: {
    id: 1036,
    name: 'SWM Infrastruktur GmbH',
    geloescht: 0,
    ort: 'München',
    plz: '80992',
    strasse: 'Emmy-Noether-Str. 2',
    netzbetreibernummer: 7248,
    eintragungsart: 2,
  },
  b_netzbetreiber_strom: {
    id: 33,
    name: 'Bayernwerk Netz GmbH',
    geloescht: 0,
    ort: 'Regensburg',
    plz: '93049',
    strasse: 'Heinkelstr. 1',
    netzbetreibernummer: 10681,
    eintragungsart: 1,
  },
  b_netzbetreiber_wasser: null,
  b_standort: null,
  bemerkung: null,
  besichtigung_ausschuss: null,
  besichtigung_datum: null,
  besichtigung_eon: null,
  betriebshaftpflicht: 0,
  buero_hausnummer: '1',
  buero_land: null,
  buero_ort: 'Testort',
  buero_ortsteil: null,
  buero_plz: '11111',
  buero_strasse: 'Teststraße',
  communication_email: null,
  eintragungsart_erg_gas: 'Darf nur Heizkessel installieren.',
  eintragungsart_erg_strom: 'Darf nur Elektroherde anschließen.',
  eintragungsart_erg_wasser: null,
  eintragungsart_gas: 1,
  eintragungsart_strom: 1,
  eintragungsart_wasser: null,
  email: 'petertestp15@gmail.com',
  ersteintragung: '2023-09-20',
  ersteintragung_gas: '2023-09-26',
  ersteintragung_wasser: null,
  fax: null,
  fax_werkstatt: null,
  firmeninhaber: null,
  firmenname1: 'BAG Ciam eFix 2',
  firmenname2: 'Test',
  firmenname3: 'test2',
  geloescht_am: null,
  geloescht_gas_am: null,
  geloescht_wasser_am: null,
  gewerbeanmeldung_datum: '2023-06-15',
  gewerbeanmeldung_ort: 'Augsburg',
  hrabnummer: 'HRA 007',
  hwkdatum: '2023-09-04',
  hwknummer: '98031',
  id: 100000307515,
  importiert: null,
  in_verlaengerung: false,
  instv_gas_am: null,
  instv_wasser_am: null,
  lfdnr: null,
  musteranlage_gas: null,
  netzportal: 1,
  pflegende_bu: '20288',
  referenznummer_gas: null,
  referenznummer_strom: null,
  referenznummer_wasser: null,
  register_letzte_aenderung: '2023-08-03',
  registergericht: 'Augsburg',
  richtlinien: null,
  richtlinien_version: null,
  status_gas: 5,
  status_gas_automatic: 1,
  status_strom: 4,
  status_strom_automatic: 0,
  status_wasser: null,
  status_wasser_automatic: null,
  telefon_geschaeft: '123',
  telefon_mobil: null,
  telefon_werkstatt: null,
  werkstatt_hausnummer: null,
  werkstatt_land: null,
  werkstatt_ort: null,
  werkstatt_ortsteil: null,
  werkstatt_plz: null,
  werkstatt_strasse: null,
  zaehlermontage_gas: '00',
  zaehlermontage_strom: '00',
  zu_adressveroeff: 'Ja',
  zu_gasinfo: 'Ja',
  zu_infoversand: 'Nein',
  zu_kommunikation_mail: 'Ja',
  zu_netzanschlussportal: 'Ja',
  zu_technikinfo: 'Ja',
};

export const bagEnergyMainFormData = {
  companyName1: 'BAG Ciam eFix 2',
  companyName2: 'Test',
  companyName3: 'test2',
  companyOwner: '',
  companyId: '100000307515',
  companyStreet: 'Teststraße',
  companyHouseNumber: '1',
  companyPostal: '11111',
  companyCity: 'Testort',
  companyRegion: '',
  gridProviderEnergy: 'Bayernwerk Netz GmbH',
  gridProviderGas: 'SWM Infrastruktur GmbH',
  foreignCompanyIdEnergy: '',
  foreignCompanyIdGas: 'xyz123',
  email: 'petertestp15@gmail.com',
  phone: '123',
  mobile: '',
  isAddressPublic: true,
  canSendMail: false,
  limitationGas: 'Darf nur Heizkessel installieren.',
  limitationEnergy: 'Darf nur Elektroherde anschließen.',
  installerGasValidityDate: '28.09.2024',
  installerEnergyValidityDate: '28.11.2024',
  energyAgreement: false,
  gasAgreement: false,
  energyExtension: false,
  gasExtension: false,
  craftsChamberArea: 'Schwaben',
  craftsChamberNumber: '98031',
  craftsChamberDate: '04.09.2023',
  businessRegistrationLocation: 'Augsburg',
  businessRegistrationDate: '15.06.2023',
  commercialRegisterNumber: 'HRA 007 Augsburg',
  commercialRegisterDate: '03.08.2023',
  experts: [
    {
      firstName: 'BAG Ciam',
      lastName: 'Test',
      expertGasValidityDate: '28.09.2024',
      expertEnergyValidityDate: '28.09.2028',
      birthdate: '04.04.1967',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 296380,
      isMainGas: true,
      isMainEnergy: true,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'FK E-Fix',
      lastName: 'Sparte Strom und Gas',
      expertGasValidityDate: '28.09.2024',
      expertEnergyValidityDate: '28.09.2028',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 296383,
      isMainGas: true,
      isMainEnergy: true,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'FK EFix',
      lastName: 'Sparte Gas',
      expertGasValidityDate: '28.09.2024',
      expertEnergyValidityDate: '28.09.2028',
      birthdate: '20.09.1955',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 296384,
      isMainGas: true,
      isMainEnergy: true,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
  ],
  doesEnergyBranchExist: true,
  doesGasBranchExist: false,
  doesNeedAvaGuestPostal: false,
  avaGuestPostal: '',
  contracts: [
    {
      id: 3825,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'BAG - Nachplombierung (Neusystem ab 2021)',
      },
      validFrom: '28.09.2023',
      cancelledOn: '',
    },
    {
      id: 3826,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '28.09.2023',
      cancelledOn: '',
    },
    {
      id: 3827,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Gas',
      },
      validFrom: '27.09.2023',
      cancelledOn: '',
    },
  ],
  seals: [
    {
      id: 16041,
      sealNumber: '007',
      status: {
        name: 'ausgegeben',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '28.09.2023',
      returnedOn: '',
      lostOn: '',
    },
    {
      id: 16042,
      sealNumber: 'M12/001',
      status: {
        name: 'ausgegeben',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '28.09.2023',
      returnedOn: '',
      lostOn: '',
    },
    {
      id: 18339,
      sealNumber: 'test',
      status: {
        name: 'verloren',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '11.04.2024',
      returnedOn: '',
      lostOn: '01.05.2024',
    },
    {
      id: 18340,
      sealNumber: 'Test2',
      status: {
        name: 'zurückgegeben',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '01.05.2024',
      returnedOn: '01.05.2024',
      lostOn: '',
    },
  ],
};
