import * as Yup from 'yup';

import { YDate, YZip } from 'utils/YupUtils';
import { getTenantId } from 'utils/tenantUtil';
import { isRegisteringInstallerGuestOnly } from 'utils/InstallerUtils';
import { isRegisteringBranchGuest } from 'utils/InstallerUtils';
import { EIV_CHAR_LENGTH, VALIDATION_CONSTANTS, BRANCH_TYPE, TENANT_ID } from 'global/constants';
import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';

export const YNEStepFormPageSchema = (branchSelectionValues) =>
  Yup.object().shape({
    companyName1: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.FIRMENNAME1, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyName2: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG),
    companyName3: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG),
    companyOwner: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return TENANT_ID.BAG === getTenantId();
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    companyStreet: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyHouseNumber: Yup.string()
      .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_HAUSNUMMER, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyPostal: Yup.string()
      .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_PLZ, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyCity: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_ORT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    companyRegion: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_ORTSTEIL, VALIDATION_CONSTANTS.TOO_LONG),
    phone: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.TELEFON_GESCHAEFT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
    mobile: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.TELEFON_MOBIL, VALIDATION_CONSTANTS.TOO_LONG),
    gridProviderEnergy: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues);
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    foreignCompanyIdEnergy: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues);
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    gridProviderGas: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues);
        },
        then: Yup.string().required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    foreignCompanyIdGas: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues);
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    experts: Yup.array().min(1, VALIDATION_CONSTANTS.MANDATORY),
    craftsChamberArea: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return (
            TENANT_ID.BAG === getTenantId() &&
            !isRegisteringInstallerGuestOnly(branchSelectionValues) &&
            !isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)
          );
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    craftsChamberNumber: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return (
            TENANT_ID.BAG === getTenantId() &&
            !isRegisteringInstallerGuestOnly(branchSelectionValues) &&
            !isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)
          );
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    craftsChamberDate: YDate.when([], {
      is: () => {
        return (
          TENANT_ID.BAG === getTenantId() &&
          !isRegisteringInstallerGuestOnly(branchSelectionValues) &&
          !isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)
        );
      },
      then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
    }),
    businessRegistrationLocation: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
      .when([], {
        is: () => {
          return (
            TENANT_ID.BAG === getTenantId() &&
            !isRegisteringInstallerGuestOnly(branchSelectionValues) &&
            !isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)
          );
        },
        then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    businessRegistrationDate: YDate.when([], {
      is: () => {
        return (
          TENANT_ID.BAG === getTenantId() &&
          !isRegisteringInstallerGuestOnly(branchSelectionValues) &&
          !isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues)
        );
      },
      then: (s) => s.required(VALIDATION_CONSTANTS.MANDATORY),
    }),
    commercialRegisterNumber: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG),
    commercialRegisterDate: YDate,
    gasAgreement: Yup.bool().when([], {
      is: () => {
        return branchSelectionValues.isGasActive === true;
      },
      then: (s) => s.oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
    }),
    energyAgreement: Yup.bool().when([], {
      is: () => {
        return branchSelectionValues.isEnergyActive === true;
      },
      then: (s) => s.oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
    }),
    avaGuestPostal: Yup.number().when([], {
      is: () => {
        return (
          TENANT_ID.AVA === getTenantId() && isRegisteringInstallerGuestOnly(branchSelectionValues)
        );
      },
      then: YZip.required(VALIDATION_CONSTANTS.MANDATORY),
    }),
  });
