import React from 'react';
import PropTypes from 'prop-types';
import { BRANCH_TYPE, NINTY_DAYS_IN_MINUTES } from 'global/constants';
import { canBranchRenderByStatus, isBranchGuest } from 'utils/InstallerUtils';
import { differenceInMinutes, parse } from 'date-fns';
import { CheckboxRow } from 'components/CheckboxRow/CheckboxRow';
import { getTodayMidnightDate } from 'utils/DatesUtils';

const ExtensionSection = ({ formik, installerStatusSummary }) => {
  const energyCanExtend =
    differenceInMinutes(
      parse(formik.values.installerEnergyValidityDate, 'dd.MM.yyyy', new Date()),
      getTodayMidnightDate()
    ) < NINTY_DAYS_IN_MINUTES;
  const gasCanExtend =
    differenceInMinutes(
      parse(formik.values.installerGasValidityDate, 'dd.MM.yyyy', new Date()),
      getTodayMidnightDate()
    ) < NINTY_DAYS_IN_MINUTES;
  return (
    <>
      {canBranchRenderByStatus(installerStatusSummary, BRANCH_TYPE.ENERGY) && (
        <>
          <CheckboxRow
            disabled={isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY) || !energyCanExtend}
            hoverText={
              isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
                ? 'Nutzen Sie bitte die Taste "Verlängern" bei der Fachkraft'
                : !energyCanExtend
                ? 'Verlängerung maximal 90 Tage vor dem Ablauf der Eintragung möglich'
                : undefined
            }
            name="energyExtension"
            label={'Ich möchte eine Verlängerung für die Sparte Strom beantragen.'}
            value={formik.values.energyExtension}
            onChange={formik.handleChange}
            editedHighlight></CheckboxRow>
        </>
      )}
      {canBranchRenderByStatus(installerStatusSummary, BRANCH_TYPE.GAS) && (
        <>
          <CheckboxRow
            name="gasExtension"
            disabled={isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS) || !gasCanExtend}
            hoverText={
              isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
                ? 'Nutzen Sie bitte die Taste "Verlängern" bei der Fachkraft'
                : !gasCanExtend
                ? 'Verlängerung maximal 90 Tage vor dem Ablauf der Eintragung möglich'
                : undefined
            }
            label={'Ich möchte eine Verlängerung für die Sparte Gas beantragen.'}
            value={formik.values.gasExtension}
            onChange={formik.handleChange}
            editedHighlight></CheckboxRow>
        </>
      )}
    </>
  );
};

ExtensionSection.propTypes = {
  formik: PropTypes.object,
  installerStatusSummary: PropTypes.object,
};

export { ExtensionSection };
