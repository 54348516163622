import {
  BAG_KEYS,
  BRANCH_TYPE,
  BRANCH_TYPE_TO_VARIABLE_NAME,
  EIV_ENTRY_TYPE_TO_GERMAN_NAME,
  EIV_STATUS,
  EIV_STATUS_TO_SHOW,
  INSTALLER_ENTRIES,
  KEYS_TO_GERMAN,
  TENANT_ID,
} from 'global/constants';
import { getTenantId } from 'utils/tenantUtil';
import { getFieldByName, isRegisteredInstallerOnlyHilfsbetrieb } from './DebFormSectionUtil';
import { prepDocumentForMutation } from 'utils/FileUtil';
import { doesInstallerHaveBranch, isBranchGuest } from 'utils/InstallerUtils';
import { isEqual } from 'lodash';
import { parseEIVDateStringToEfixDateString } from 'utils/DatesUtils';
import { DEB_CURRENT_TEST_SUITE, shouldFillTestData } from './DebTestSetup';
import { getInstallersContractsByInstallerId } from 'api/installer/GetInstallersContractsByInstallerId';
import { getInstallersSealsByInstallerId } from 'api/installer/GetInstallersSealsByInstallerId';
import { prepValueForInitialData, prepValueForEmail } from 'utils/DataUtils';
import { evaluateCondition } from 'global/fieldValidationFunction';

export const prepDebDataForBackend = (
  initialData,
  firstPageValues,
  secondPageValues,
  subject,
  documents,
  installerStatusSummary
) => {
  const formDataEntries = [];
  const expertEntries = prepDebExperts(initialData, firstPageValues, installerStatusSummary);

  Object.keys(KEYS_TO_GERMAN).forEach((key) => {
    const field = getFieldByName(key);
    if (
      evaluateCondition(field.whenShouldFieldRender, {
        isOnlyHilfsbetrieb: isRegisteredInstallerOnlyHilfsbetrieb(installerStatusSummary),
        installerStatusSummary,
      })
    ) {
      const entry = {
        first: KEYS_TO_GERMAN[key],
        second: {
          first: prepValueForEmail(firstPageValues[key]),
          second: prepValueForEmail(initialData[key]),
        },
      };

      if (BAG_KEYS.includes(key) && getTenantId() == TENANT_ID.BAG) {
        formDataEntries.push(entry);
      } else if (!BAG_KEYS.includes(key)) {
        formDataEntries.push(entry);
      }
    }
  });

  const documentEntries = documents.map(prepDocumentForMutation);

  const gasType = prepValueForEmail(
    EIV_ENTRY_TYPE_TO_GERMAN_NAME[installerStatusSummary[BRANCH_TYPE.GAS].entryType]
  );
  const energyType = prepValueForEmail(
    EIV_ENTRY_TYPE_TO_GERMAN_NAME[installerStatusSummary[BRANCH_TYPE.ENERGY].entryType]
  );
  const isEnergyExtended = firstPageValues.energyExtension;

  const isGasExtented = firstPageValues.gasExtension;
  const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);
  const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

  const baseDataEntries = [
    { first: 'Email', second: initialData.email },
    { first: 'Eintragungsnummer', second: initialData.companyId },
    ...(getTenantId() == TENANT_ID.BAG && hasEnergyRow
      ? [{ first: 'Beschränkung Strom', second: initialData.limitationEnergy }]
      : []),
    ...(getTenantId() == TENANT_ID.BAG && hasGasRow
      ? [{ first: 'Beschränkung Gas', second: initialData.limitationGas }]
      : []),
    ...(hasEnergyRow
      ? [
          {
            first: 'Status Strom Eintragung',
            second: `${energyType} ${isEnergyExtended ? 'verlängert' : ''}`,
          },
        ]
      : []),
    ...(hasEnergyRow
      ? [
          {
            first: 'Strom gültig bis',
            second: installerStatusSummary[BRANCH_TYPE.ENERGY].expireDate,
          },
        ]
      : []),
    ...(hasGasRow
      ? [
          {
            first: 'Status Gas Eintragung',
            second: `${gasType} ${isGasExtented ? 'verlängert' : ''}`,
          },
        ]
      : []),
    ...(hasGasRow
      ? [
          {
            first: 'Gas gültig bis',
            second: installerStatusSummary[BRANCH_TYPE.GAS].expireDate,
          },
        ]
      : []),
    { first: 'Anmerkung des Installateurs', second: prepValueForEmail(secondPageValues.changes) },
  ];

  if (getTenantId() == TENANT_ID.AVA) {
    baseDataEntries.push({
      first: 'PLZ Auftragsort',
      second: firstPageValues.doesNeedAvaGuestPostal
        ? firstPageValues.avaGuestPostal
        : firstPageValues.companyPostal,
    });
  }

  const sealsEntries = prepDebSeals(initialData, firstPageValues);
  return {
    email: initialData.email,
    subject,
    baseDataEntries,
    formDataEntries,
    expertEntries,
    sealsEntries,
    documents: documentEntries,
  };
};

const prepDebSeals = (initialData, firstPageValues) => {
  const sealsEntries = firstPageValues.seals.map((seal) => {
    const oldSeal = initialData.seals.find((searchSeal) => searchSeal.id == seal.id);
    let sealAction = '';
    if (isEqual(oldSeal, seal)) {
      sealAction = 'Unverändert';
    } else {
      if (seal.lostDate !== oldSeal.lostDate) {
        sealAction = 'Verloren';
      }
    }
    return [
      {
        first: 'Änderung',
        second: {
          first: sealAction,
          second: 'Unverändert',
        },
      },
      {
        first: 'Id',
        second: {
          first: seal.id,
          second: oldSeal.id,
        },
      },
      {
        first: 'Zangen/Nuss Nr.',
        second: {
          first: seal.sealNumber,
          second: oldSeal.sealNumber,
        },
      },
      ...(seal.lostDate
        ? [
            {
              first: 'Verlustdatum',
              second: {
                first: seal.lostDate,
                second: '-',
              },
            },
          ]
        : []),
      ...(seal.lostDate
        ? [
            {
              first: 'Grund des Verlustes',
              second: {
                first: prepValueForEmail(seal.lostReason),
                second: '-',
              },
            },
          ]
        : []),
    ];
  });
  return sealsEntries;
};

const isExpertBranchExtendedFromCheckbox = (
  expert,
  branch,
  installerStatusSummary,
  branchCheckboxValue
) => {
  if (
    !isBranchGuest(installerStatusSummary, branch) &&
    EIV_STATUS_TO_SHOW.includes(expert[`status${BRANCH_TYPE_TO_VARIABLE_NAME[branch]}`])
  ) {
    return branchCheckboxValue;
  } else {
    return false;
  }
};

const getExpertActions = (expert, oldExpert, firstPageValues, installerStatusSummary) => {
  let energyAction = 'Unverändert';
  let gasAction = 'Unverändert';

  if (!oldExpert) {
    if (expert.statusGas == EIV_STATUS.NEW) {
      gasAction = 'Neu';
    }
    if (expert.statusEnergy === EIV_STATUS.NEW) {
      energyAction = 'Neu';
    }
    return {
      energyAction,
      gasAction,
    };
  }

  const isEnergyBranchExtendedFromCheckbox = isExpertBranchExtendedFromCheckbox(
    expert,
    BRANCH_TYPE.ENERGY,
    installerStatusSummary,
    firstPageValues.energyExtension
  );
  const isGasBranchExtendedFromCheckbox = isExpertBranchExtendedFromCheckbox(
    expert,
    BRANCH_TYPE.GAS,
    installerStatusSummary,
    firstPageValues.gasExtension
  );
  if (
    !isEnergyBranchExtendedFromCheckbox &&
    !isGasBranchExtendedFromCheckbox &&
    isEqual(expert, oldExpert)
  ) {
    energyAction = 'Unverändert';
    gasAction = 'Unverändert';
  }

  //EXTENDED ACTION FOR GAS AND BOTH

  if (EIV_STATUS_TO_SHOW.includes(expert.statusGas)) {
    if (isGasBranchExtendedFromCheckbox) {
      gasAction = 'Verlängert';
    }
    if (
      expert.expertGasValidityDate &&
      expert.expertGasValidityDate != oldExpert?.expertGasValidityDate
    ) {
      gasAction = 'Verlängert';
    }
  }
  //EXTENDED ACTION FOR ENERGY

  if (EIV_STATUS_TO_SHOW.includes(expert.statusEnergy)) {
    if (isEnergyBranchExtendedFromCheckbox) {
      energyAction = 'Verlängert ';
    }
    if (
      expert.expertEnergyValidityDate &&
      expert.expertEnergyValidityDate != oldExpert?.expertEnergyValidityDate
    ) {
      energyAction = 'Verlängert';
    }
  }

  //DELETED ACTION
  if (expert.statusGas == EIV_STATUS.DELETED) {
    gasAction = 'Gelöscht';
  }
  if (expert.statusEnergy == EIV_STATUS.DELETED) {
    energyAction = 'Gelöscht';
  }

  return {
    energyAction,
    gasAction,
  };
};

const prepDebExperts = (initialData, firstPageValues, installerStatusSummary) => {
  const experts = [...firstPageValues.experts];
  const expertEntries = [];
  const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);
  const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

  const isExpertValid = (expert) => {
    return (
      expert &&
      ((hasGasRow && EIV_STATUS_TO_SHOW.includes(expert.statusGas)) ||
        (hasEnergyRow && EIV_STATUS_TO_SHOW.includes(expert.statusEnergy)))
    );
  };

  experts.forEach((expert) => {
    if (isExpertValid(expert)) {
      const oldExpert = initialData.experts.find((search) => {
        return search.id == expert.id;
      });
      const userActions = getExpertActions(
        expert,
        oldExpert,
        firstPageValues,
        installerStatusSummary
      );
      const shouldTryShowingValidityDate = ['Verlängert', 'Neu'];

      const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);
      const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

      expertEntries.push([
        ...(hasEnergyRow
          ? [
              {
                first: 'Änderung Strom',
                second: { first: userActions.energyAction, second: 'Unverändert' },
              },
            ]
          : []),
        ...(hasGasRow
          ? [
              {
                first: 'Änderung Gas',
                second: { first: userActions.gasAction, second: 'Unverändert' },
              },
            ]
          : []),

        ...(expert.statusGas != EIV_STATUS.NEW || expert.statusEnergy != EIV_STATUS.NEW
          ? [
              {
                first: 'Id',
                second: { first: expert.id, second: expert.id },
              },
            ]
          : []),
        {
          first: 'Vorname',
          second: { first: expert.firstName, second: prepValueForEmail(oldExpert?.firstName) },
        },
        {
          first: 'Nachname',
          second: { first: expert.lastName, second: prepValueForEmail(oldExpert?.lastName) },
        },
        ...(shouldTryShowingValidityDate.includes(userActions.energyAction) &&
        expert.expertEnergyValidityDate
          ? [
              {
                first: 'Stromausweis Gültigkeitsdatum',
                second: {
                  first: prepValueForEmail(expert.expertEnergyValidityDate),
                  second: prepValueForEmail(oldExpert?.expertEnergyValidityDate),
                },
              },
            ]
          : []),
        ...(shouldTryShowingValidityDate.includes(userActions.gasAction) &&
        expert.expertGasValidityDate
          ? [
              {
                first: 'Gasausweis Gültigkeitsdatum',
                second: {
                  first: prepValueForEmail(expert.expertGasValidityDate),
                  second: prepValueForEmail(oldExpert?.expertGasValidityDate),
                },
              },
            ]
          : []),
        ...(getTenantId() === TENANT_ID.BAG
          ? [
              {
                first: 'Geburtsdatum',
                second: {
                  first: prepValueForEmail(expert.birthdate),
                  second: prepValueForEmail(oldExpert?.birthdate),
                },
              },
            ]
          : []),
        ...(expert.statusEnergy === EIV_STATUS.DELETED
          ? [
              {
                first: 'Grund der Löschung Strom',
                second: {
                  first: prepValueForEmail(expert.deletionReasonEnergy),
                  second: '-',
                },
              },
            ]
          : []),
        ...(expert.statusEnergy === EIV_STATUS.DELETED
          ? [
              {
                first: 'Löschdatum Strom',
                second: {
                  first: prepValueForEmail(expert.deletionDateEnergy),
                  second: '-',
                },
              },
            ]
          : []),
        ...(expert.statusGas === EIV_STATUS.DELETED
          ? [
              {
                first: 'Grund der Löschung Gas',
                second: {
                  first: prepValueForEmail(expert.deletionReasonGas),
                  second: '-',
                },
              },
            ]
          : []),

        ...(expert.statusGas === EIV_STATUS.DELETED
          ? [
              {
                first: 'Löschdatum Gas',
                second: {
                  first: prepValueForEmail(expert.deletionDateGas),
                  second: '-',
                },
              },
            ]
          : []),
      ]);
    }
  });
  return expertEntries;
};

export const prepareDEBInitialData = (installer, experts) => {
  const data = {
    companyName1: prepValueForInitialData(installer.firmenname1),
    companyName2: prepValueForInitialData(installer.firmenname2),
    companyName3: prepValueForInitialData(installer.firmenname3),
    companyOwner: prepValueForInitialData(installer.firmeninhaber),
    companyId: `${installer.id}`,
    companyStreet: prepValueForInitialData(installer.buero_strasse),
    companyHouseNumber: prepValueForInitialData(installer.buero_hausnummer),
    companyPostal: prepValueForInitialData(installer.buero_plz),
    companyCity: prepValueForInitialData(installer.buero_ort),
    companyRegion: prepValueForInitialData(installer.buero_ortsteil),
    gridProviderEnergy: prepValueForInitialData(installer?.b_netzbetreiber_strom?.name),
    gridProviderGas: prepValueForInitialData(installer?.b_netzbetreiber_gas?.name),
    foreignCompanyIdEnergy: prepValueForInitialData(installer.ausweisnr_fremd_strom),
    foreignCompanyIdGas: prepValueForInitialData(installer.ausweisnr_fremd_gas),
    email: prepValueForInitialData(installer.email),
    phone: prepValueForInitialData(installer.telefon_geschaeft),
    mobile: prepValueForInitialData(installer.telefon_mobil),
    isAddressPublic: installer.zu_adressveroeff === 'Ja',
    canSendMail: installer.zu_infoversand === 'Ja',
    limitationGas: prepValueForInitialData(installer.eintragungsart_erg_gas),
    limitationEnergy: prepValueForInitialData(installer.eintragungsart_erg_strom),
    installerGasValidityDate: prepValueForInitialData(
      parseEIVDateStringToEfixDateString(installer.ausw_gas_gueltig)
    ),
    installerEnergyValidityDate: prepValueForInitialData(
      parseEIVDateStringToEfixDateString(installer.ausw_strom_gueltig)
    ),
    energyAgreement: false,
    gasAgreement: false,
    energyExtension: false,
    gasExtension: false,
    craftsChamberArea: prepValueForInitialData(installer.b_hwkbereich?.name),
    craftsChamberNumber: prepValueForInitialData(installer.hwknummer),
    craftsChamberDate: parseEIVDateStringToEfixDateString(installer.hwkdatum),
    businessRegistrationLocation: prepValueForInitialData(installer.gewerbeanmeldung_ort),
    businessRegistrationDate: parseEIVDateStringToEfixDateString(installer.gewerbeanmeldung_datum),
    commercialRegisterNumber: installer.hrabnummer
      ? `${installer.hrabnummer} ${prepValueForInitialData(installer.registergericht)}`
      : '',
    commercialRegisterDate: parseEIVDateStringToEfixDateString(installer.register_letzte_aenderung),
    experts: experts.map((expert) => {
      return {
        firstName: prepValueForInitialData(expert.vorname),
        lastName: prepValueForInitialData(expert.nachname),
        expertGasValidityDate: parseEIVDateStringToEfixDateString(
          prepValueForInitialData(expert.gueltig_bis_gas, installer.ausw_gas_gueltig)
        ),
        expertEnergyValidityDate: parseEIVDateStringToEfixDateString(
          prepValueForInitialData(expert.gueltig_bis_strom, installer.ausw_strom_gueltig)
        ),
        birthdate: parseEIVDateStringToEfixDateString(expert.geburtsdatum),
        statusGas: expert.status_gas ? expert.status_gas : EIV_STATUS.NOT_SET,
        statusEnergy: expert.status_strom ? expert.status_strom : EIV_STATUS.NOT_SET,
        deletionReasonEnergy: '',
        deletionReasonGas: '',
        deletionDateEnergy: '',
        deletionDateGas: '',
        id: prepValueForInitialData(expert.id),
      };
    }),
  };

  return data;
};

export const prepareDEBCompleteData = async (installer, experts, status, setShowError) => {
  if (shouldFillTestData()) {
    return DEB_CURRENT_TEST_SUITE.formData;
  }
  let finalObject = prepareDEBInitialData(installer, experts);
  finalObject.doesEnergyBranchExist = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, status);
  finalObject.doesGasBranchExist = doesInstallerHaveBranch(BRANCH_TYPE.GAS, status);

  const doesNeedAvaGuestPostal =
    getTenantId() === TENANT_ID.AVA && status.installerEntry === INSTALLER_ENTRIES.GUEST_ONLY;

  finalObject.doesNeedAvaGuestPostal = doesNeedAvaGuestPostal;
  finalObject.avaGuestPostal = prepValueForInitialData('');

  const expertsFixed = finalObject.experts.map((expert) => {
    return {
      ...expert,
      isMainGas: status[BRANCH_TYPE.GAS].entryType === 'main',
      isMainEnergy: status[BRANCH_TYPE.ENERGY].entryType === 'main',
      editingStatusGas: prepValueForInitialData(''),
      editingStatusEnergy: prepValueForInitialData(''),
    };
  });

  finalObject.experts = expertsFixed;

  await getInstallersContractsByInstallerId(installer.id, setShowError).then(
    async (installerContractsRes) => {
      finalObject.contracts = installerContractsRes.map((contract) => {
        let contractReturn = {};

        contractReturn.id = prepValueForInitialData(contract.id);
        contractReturn.contractStatus = prepValueForInitialData(contract.vertragsstatus);
        contractReturn.contractType = {
          name: prepValueForInitialData(contract.vertragsart.name),
        };
        contractReturn.validFrom = parseEIVDateStringToEfixDateString(
          prepValueForInitialData(contract.abgeschlossen_am)
        );
        contractReturn.cancelledOn = parseEIVDateStringToEfixDateString(
          prepValueForInitialData(contract.gekundigtzum)
        );

        return contractReturn;
      });
      await getInstallersSealsByInstallerId(installer.id, setShowError).then((sealsRes) => {
        finalObject.seals = sealsRes.map((seal) => {
          let sealsReturn = {};

          sealsReturn.id = prepValueForInitialData(seal.id);
          sealsReturn.sealNumber = prepValueForInitialData(seal.zangennr);
          sealsReturn.status = { name: prepValueForInitialData(seal.status?.name) };
          sealsReturn.lostDate = prepValueForInitialData('');
          sealsReturn.lostReason = prepValueForInitialData('');
          sealsReturn.issuedOn = parseEIVDateStringToEfixDateString(
            prepValueForInitialData(seal.ausgegeben_am)
          );
          sealsReturn.returnedOn = parseEIVDateStringToEfixDateString(
            prepValueForInitialData(seal.zurueckgegeben_am)
          );
          sealsReturn.lostOn = parseEIVDateStringToEfixDateString(
            prepValueForInitialData(seal.verlust_am)
          );

          return sealsReturn;
        });
      });
    }
  );
  return finalObject;
};
