export const avaconGuestBothFormData = {
  companyName1: 'RPA Testaccount',
  companyName2: 'Company name',
  companyName3: 'Line 3',
  companyOwner: '',
  companyId: '100000302473',
  companyStreet: 'Celler Str.',
  companyHouseNumber: '123',
  companyPostal: '29221',
  companyCity: 'Celle',
  companyRegion: 'Ortsteil',
  gridProviderEnergy: 'Celle-Uelzen Netz GmbH',
  gridProviderGas: 'Celle-Uelzen Netz GmbH',
  foreignCompanyIdEnergy: 'Strom-AAASSS',
  foreignCompanyIdGas: 'Gas-BBBGGG',
  email: 'robotautomation28@gmail.com',
  phone: '+49 1212121212',
  mobile: '+49 152 222233333',
  isAddressPublic: false,
  canSendMail: false,
  limitationGas: '',
  limitationEnergy: '',
  installerGasValidityDate: '',
  installerEnergyValidityDate: '31.12.2028',
  energyAgreement: false,
  gasAgreement: false,
  energyExtension: false,
  gasExtension: false,
  craftsChamberArea: '',
  craftsChamberNumber: '',
  craftsChamberDate: '',
  businessRegistrationLocation: '',
  businessRegistrationDate: '',
  commercialRegisterNumber: '',
  commercialRegisterDate: '',
  experts: [
    {
      firstName: 'Sebastian',
      lastName: 'Starkstrom',
      expertGasValidityDate: '',
      expertEnergyValidityDate: '',
      birthdate: '',
      statusGas: null,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290648,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Installateur',
      lastName: 'NEU_Nov21-11-2024',
      expertGasValidityDate: '',
      expertEnergyValidityDate: '',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 320269,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Installateur',
      lastName: 'NEU_Nov21-11-2024',
      expertGasValidityDate: '',
      expertEnergyValidityDate: '',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 320270,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Installateur',
      lastName: 'NEU_Nov21-11-2024',
      expertGasValidityDate: '',
      expertEnergyValidityDate: '',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 320271,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Installateur',
      lastName: 'NEU_Nov21-11-2024',
      expertGasValidityDate: '',
      expertEnergyValidityDate: '',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 320272,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
  ],
  doesEnergyBranchExist: true,
  doesGasBranchExist: true,
  doesNeedAvaGuestPostal: true,
  avaGuestPostal: '',
  contracts: [],
  seals: [],
};

export const avaconGuestBothInstallerData = {
  anredename: null,
  ausw_gas_datum: null,
  ausw_gas_gueltig: '2026-12-31',
  ausw_probe_gas: 0,
  ausw_probe_strom: 0,
  ausw_probe_wasser: 0,
  ausw_strom_datum: '2023-06-26',
  ausw_strom_gueltig: '2027-12-31',
  ausw_strom_matrizennr: null,
  ausw_wasser_datum: null,
  ausw_wasser_gueltig: null,
  ausweisnr_fremd_gas: null,
  ausweisnr_fremd_strom: null,
  ausweisnr_fremd_wasser: null,
  b_anrede: null,
  b_betriebsart_gas: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_strom: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_wasser: null,
  b_briefanrede: {
    id: 28,
    name: 'Guten Tag',
    geloescht: 0,
  },
  b_dezentrale_einheit_gas: null,
  b_dezentrale_einheit_strom: null,
  b_dezentrale_einheit_wasser: null,
  b_eintragung_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_gas_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_wasser_als: null,
  b_hwkbereich: null,
  b_netzbetreiber_gas: {
    id: 32,
    name: 'Avacon Netz GmbH',
    geloescht: 0,
    ort: 'Helmstedt',
    plz: '38350',
    strasse: 'Schillerstraße 3',
    netzbetreibernummer: 7137,
    eintragungsart: 1,
  },
  b_netzbetreiber_strom: {
    id: 936,
    name: 'Stadtwerke Überlingen GmbH',
    geloescht: 0,
    ort: 'Überlingen',
    plz: '88662',
    strasse: 'Kurt-Wilde-Str. 10',
    netzbetreibernummer: 643,
    eintragungsart: 2,
  },
  b_netzbetreiber_wasser: null,
  b_standort: null,
  bemerkung: null,
  besichtigung_ausschuss: null,
  besichtigung_datum: null,
  besichtigung_eon: null,
  betriebshaftpflicht: 0,
  buero_hausnummer: '123',
  buero_land: null,
  buero_ort: 'Salzgitter',
  buero_ortsteil: null,
  buero_plz: '38226',
  buero_strasse: 'Hopfenstraße',
  communication_email: null,
  eintragungsart_erg_gas: null,
  eintragungsart_erg_strom: null,
  eintragungsart_erg_wasser: null,
  eintragungsart_gas: 2,
  eintragungsart_strom: 2,
  eintragungsart_wasser: null,
  email: 'manuel.elgomez@gmail.com',
  ersteintragung: '2023-06-26',
  ersteintragung_gas: '2023-12-01',
  ersteintragung_wasser: null,
  fax: null,
  fax_werkstatt: null,
  firmeninhaber: null,
  firmenname1: 'Avacon Test (QA EIV) / DEV Efix',
  firmenname2: 'Avacon Manuel Gumz',
  firmenname3: 'Line 3 Testing',
  geloescht_am: null,
  geloescht_gas_am: null,
  geloescht_wasser_am: null,
  gewerbeanmeldung_datum: null,
  gewerbeanmeldung_ort: null,
  hrabnummer: null,
  hwkdatum: null,
  hwknummer: null,
  id: 100000302511,
  importiert: null,
  in_verlaengerung: false,
  instv_gas_am: null,
  instv_wasser_am: null,
  lfdnr: null,
  musteranlage_gas: null,
  netzportal: 1,
  pflegende_bu: '2235',
  referenznummer_gas: null,
  referenznummer_strom: null,
  referenznummer_wasser: null,
  register_letzte_aenderung: null,
  registergericht: null,
  richtlinien: null,
  richtlinien_version: null,
  status_gas: 4,
  status_gas_automatic: 1,
  status_strom: 4,
  status_strom_automatic: 1,
  status_wasser: null,
  status_wasser_automatic: 0,
  telefon_geschaeft: '+49 1325 45642 4',
  telefon_mobil: null,
  telefon_werkstatt: null,
  werkstatt_hausnummer: null,
  werkstatt_land: null,
  werkstatt_ort: null,
  werkstatt_ortsteil: null,
  werkstatt_plz: null,
  werkstatt_strasse: null,
  zaehlermontage_gas: '00',
  zaehlermontage_strom: '01',
  zu_adressveroeff: 'Nein',
  zu_gasinfo: null,
  zu_infoversand: 'Nein',
  zu_kommunikation_mail: null,
  zu_netzanschlussportal: null,
  zu_technikinfo: null,
};
