/* eslint-disable react/prop-types */
import React from 'react';
import './ContactPersonItem.sass';

const ContactPersonItemComponent = ({
  title = '',
  name,
  phoneNumber,
  phoneNumbers,
  phoneNumberDisplay = [],
  email = '',
  emails = [],
  additionalContainerStyle = {},
}) => (
  <div className="contact-person-item" style={additionalContainerStyle}>
    <div className="contact-person-item__title">{title || <span>&nbsp;</span>}</div>
    <div className="contact-person-item__name">{name}</div>
    {phoneNumber && (
      <div className="contact-person-item__phone">
        <a href={`tel:${phoneNumber}`}>
          <span className="contact-person-item-phone__icon">T</span>
          {phoneNumberDisplay}
        </a>
      </div>
    )}
    {phoneNumbers ? (
      <div className="contact-person-item__multi-contact-item">
        {phoneNumbers.map((phoneNumber, i) => {
          return (
            <div key={phoneNumber}>
              <div className="contact-person-item__phone">
                <a href={`tel:${phoneNumber}`}>
                  <span className="contact-person-item-phone__icon">T</span>
                  {phoneNumberDisplay[i]}
                </a>
              </div>
              <div className="contact-person-item__email" key={emails[i]}>
                <a href={`mailto:${emails[i]}`} className="email">
                  {emails[i]}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    ) : null}

    {email && (
      <div className="contact-person-item__email">
        <a href={`mailto:${email}`} className="email">
          {email}
        </a>
      </div>
    )}
  </div>
);

export default ContactPersonItemComponent;
