import { BRANCH_TYPE, FORM_CONSTANTS, INSTALLER_ENTRIES, TENANT_ID } from 'global/constants';
import {
  isInstallerRegisteringForBranch,
  isRegisteringBranchGuest,
  doesInstallerHaveBranch,
  isBranchGuest,
  isRegisteringInstallerGuestOnly,
} from 'utils/InstallerUtils';
import { getTenantId } from 'utils/tenantUtil';

export const VALIDATION_CONDITIONS = {
  CONDITION: 'condition',
  AND: 'and',
  OR: 'or',
  NOT: 'not',
  VALUE: 'value',
};

export const onlyHilfsbetrieb = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return installerData.isOnlyHilfsbetrieb === true;
  },
};

export const onlyNewEntry = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return installerData.form === FORM_CONSTANTS.NE;
  },
};
export const onlyDEB = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return installerData.form === FORM_CONSTANTS.DEB;
  },
};
export const never = {
  type: VALIDATION_CONDITIONS.VALUE,
  evaluate: () => {
    return false;
  },
};
export const always = {
  type: VALIDATION_CONDITIONS.VALUE,
  evaluate: () => {
    return true;
  },
};
export const onlyBAG = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: () => {
    return getTenantId() === TENANT_ID.BAG;
  },
};
export const onlyAVA = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: () => {
    return getTenantId() === TENANT_ID.AVA;
  },
};

export const onlyGuestNE = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return isRegisteringInstallerGuestOnly(installerData.branchSelectionValues);
  },
};
export const onlyGuestDEB = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return installerData.installerStatusSummary.installerEntry === INSTALLER_ENTRIES.GUEST_ONLY;
  },
};
export const isRegisteredInstallerGasGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return (
      doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerData.installerStatusSummary) &&
      isBranchGuest(installerData.installerStatusSummary, BRANCH_TYPE.GAS)
    );
  },
};
export const isRegisteredInstallerEnergyGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return (
      doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerData.installerStatusSummary) &&
      isBranchGuest(installerData.installerStatusSummary, BRANCH_TYPE.ENERGY)
    );
  },
};

export const isRegisteringInstallerGasGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return (
      isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, installerData.branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.GAS, installerData.branchSelectionValues)
    );
  },
};
export const isRegisteringInstallerEnergyGuest = {
  type: VALIDATION_CONDITIONS.CONDITION,
  evaluate: (installerData) => {
    return (
      isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, installerData.branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, installerData.branchSelectionValues)
    );
  },
};
