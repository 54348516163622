import { Utils } from '@adobe/aem-react-editable-components';
import { isDevelopment, isLocal, isStaging } from './StageUtils';
import LOGO_EDI from '../assets/pics/logo_edis.png';
import LOGO_SHNG from '../assets/pics/logo_sh-netz.png';
import LOGO_AVA from '../assets/pics/logo_avacon.png';
import LOGO_BAG from '../assets/pics/logo_bag.png';
import { TENANT_ID, STORAGE_KEY, PFLEGENDE_BU } from 'global/constants';
export const GRIDNET_PROVIDER = {
  [TENANT_ID.EDI]: {
    EDI: 35,
  },
  [TENANT_ID.SHNG]:
    isLocal() || isDevelopment()
      ? {
          SHNG: 36,
          EENG: 1560,
          NNNG: 1569,
          HANSG: 1568,
        }
      : isStaging()
      ? {
          SHNG: 36,
          EENG: 1560,
          NNNG: 1567,
          HANSG: 1566,
        }
      : {
          SHNG: 36,
          EENG: 1560,
          NNNG: 1581,
          HANSG: 1534,
        },
  [TENANT_ID.AVA]: {
    AVA: 32,
  },
  [TENANT_ID.BAG]: {
    BAG: 33,
  },
};

export const PFLEGENDE_BU_TO_GRIDNET_PROVIDER = {
  [TENANT_ID.EDI]: {
    [PFLEGENDE_BU.EDI]: GRIDNET_PROVIDER.EDI,
  },
  [TENANT_ID.SHNG]: {
    [PFLEGENDE_BU.SHNG]: GRIDNET_PROVIDER.SHNG,
    [PFLEGENDE_BU.EENG]: GRIDNET_PROVIDER.EENG,
    [PFLEGENDE_BU.NNNG]: GRIDNET_PROVIDER.NNNG,
    [PFLEGENDE_BU.HANSG]: GRIDNET_PROVIDER.HANSG,
  },
  [TENANT_ID.AVA]: {
    [PFLEGENDE_BU.AVA]: GRIDNET_PROVIDER.AVA,
    [PFLEGENDE_BU.WEVG]: GRIDNET_PROVIDER.AVA,
  },
  [TENANT_ID.BAG]: {
    [PFLEGENDE_BU.BAG]: GRIDNET_PROVIDER.BAG,
  },
};

const TENANT_INFO = {
  [TENANT_ID.EDI]: {
    subDomain: 'edis',
    aemPath: 'edis',
    logo: LOGO_EDI,
  },
  [TENANT_ID.SHNG]: {
    subDomain: 'sh-netz',
    aemPath: 'shng',
    logo: LOGO_SHNG,
  },
  [TENANT_ID.AVA]: {
    subDomain: 'avacon',
    aemPath: 'avacon',
    logo: LOGO_AVA,
  },
  [TENANT_ID.BAG]: {
    subDomain: 'bayernwerk',
    aemPath: 'bag',
    logo: LOGO_BAG,
  },
};

export const getBUEmail = () => {
  const emails = {
    [TENANT_ID.AVA]: 'netzanschluss@avacon.de',
    [TENANT_ID.BAG]: 'installateure@bayernwerk.de',
    [TENANT_ID.EDI]: 'installateurbetreuung@e-dis.de',
    [TENANT_ID.SHNG]: 'installateurverzeichnis-strom@sh-netz.com',
  };
  return emails[getTenantId()];
};
const tenantUtil = {
  getTenantId: () => {
    const isSPAEditor = Utils.isInEditor();
    const tenantIdLocalStorage = localStorage.getItem(STORAGE_KEY.TENANT_ID);
    if (!isSPAEditor && tenantIdLocalStorage) {
      return tenantIdLocalStorage;
    } else {
      // env is only set for clientLib generation (see npm run build:clientLib:${tenantId}})
      const tenantIdEnv = getTenantIdFromEnv();
      const tenantIdUrl = getTenantIdFromUrl();
      // fallback is EDI, but should never happen
      const tenantId = tenantIdEnv || tenantIdUrl || TENANT_ID.EDI;
      localStorage.setItem(STORAGE_KEY.TENANT_ID, tenantId);
      return tenantId;
    }
  },
  getTenantInfo: (tenantId) => {
    console.log('getTenantInfo');
    const actualTenantId = tenantId || getTenantId();
    return TENANT_INFO[actualTenantId];
  },
};

const getTenantIdFromEnv = () => {
  return process.env.REACT_APP_TENANT_ID;
};

const getTenantIdFromUrl = () => {
  if (isLocal()) {
    return Object.keys(TENANT_ID).find((tenantId) =>
      window.location.pathname.includes(TENANT_INFO[tenantId].aemPath)
    );
  } else {
    return Object.keys(TENANT_ID).find((tenantId) =>
      window.location.host.includes(TENANT_INFO[tenantId].subDomain)
    );
  }
};

export const isBUofCurrentTenant = (buId) => {
  const tenantId = getTenantId();

  return Object.values(PFLEGENDE_BU[tenantId]).includes(buId);
};

export const isGridnetProviderOfCurrentTenant = (id) => {
  const tenantId = getTenantId();

  return Object.values(GRIDNET_PROVIDER[tenantId]).includes(id);
};
export const { getTenantId, getTenantInfo } = tenantUtil;
