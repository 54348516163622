import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';
import {
  NE_DOCUMENTS_FIELDS,
  NE_EXPERTS_DOCUMENTS_FIELDS,
} from 'components/NEForm/Utils/DocumentsFields';
import { getInstallerEntryByExpert } from 'components/NEForm/Utils/NEDataUtils';
import { FORM_CONSTANTS, VALIDATION_CONSTANTS } from 'global/constants';
import { useMemo } from 'react';
import { getTenantId } from 'utils/tenantUtil';
import * as Yup from 'yup';

export const useNEFormUploadIV = (valuesFirstPage, installerEntry, branchSelectionValues) => {
  const calculateExpertTriggers = (experts, branchSelectionValues) => {
    let expertTriggers = {};
    experts.forEach((expert) => {
      const triggers = NE_EXPERTS_DOCUMENTS_FIELDS[
        getInstallerEntryByExpert(expert, branchSelectionValues)
      ][getTenantId()]
        .map((field) => field.id)
        .filter((id) => {
          if (expert.isExpertOwner) {
            return id !== 23;
          }
          return true;
        });
      expertTriggers[expert.id] = triggers;
    });
    return expertTriggers;
  };

  const calculateDocumentTriggers = (installerEntry, branchSelectionValues) => {
    let triggers = NE_DOCUMENTS_FIELDS[installerEntry][getTenantId()].map((field) => field.id);

    let isHilfsbetrieb = isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues);

    if (isHilfsbetrieb) {
      triggers = triggers.filter((id) => id !== 17);
    }
    return triggers;
  };

  const createInitialValues = (experts, documentTriggerIds, expertTriggers) => {
    const initialValues = {
      experts: experts,
      documentsTrigger: documentTriggerIds,
      expertTrigger: expertTriggers,
      isAddressPublic: false,
      canSendMail: false,
      changes: '',
    };

    return initialValues;
  };

  const createValidationSchema = (
    initialValues,
    installerEntry,
    expertTriggers,
    experts,
    branchSelectionValues,
    documentTriggerIds
  ) => {
    let validationObject = {};
    const documentsNotMandatoryWhenHilfsbetrieb = [
      'businessRegistrationChange',
      'commercialRegisterExtract',
    ];
    NE_DOCUMENTS_FIELDS[installerEntry][getTenantId()]
      .filter((section) => {
        return documentTriggerIds.includes(section.id);
      })
      .forEach((section) => {
        validationObject[section.name] = Yup.array().when([], {
          is: () => {
            return (
              section.optional ||
              section.optionalNE ||
              (documentsNotMandatoryWhenHilfsbetrieb.includes(section.name) &&
                isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues))
            );
          },
          then: Yup.array(),
          otherwise: Yup.array().min(1).required(),
        });

        initialValues[section.name] = [];
      });

    Object.keys(expertTriggers).map((key) => {
      let expertValidation = {};
      const expert = experts.find((expert) => expert.id === key);
      initialValues[key] = {};
      NE_EXPERTS_DOCUMENTS_FIELDS[getInstallerEntryByExpert(expert, branchSelectionValues)][
        getTenantId()
      ]
        .filter((section) => {
          return expertTriggers[key].includes(section.id);
        })
        .forEach((section) => {
          expertValidation = {
            ...expertValidation,
            [section.name]: Yup.array().when([], {
              is: () => section.optional,
              then: Yup.array(),
              otherwise: Yup.array().min(1).required(),
            }),
          };
          initialValues[key][section.name] = [];
        });
      validationObject[key] = Yup.object().shape(expertValidation);
    });

    const validationSchema = Yup.object().shape({
      changes: Yup.string()
        .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
        .max(FORM_CONSTANTS.FREETEXT_INPUT, VALIDATION_CONSTANTS.TOO_LONG),
      isAddressPublic: Yup.bool(),
      canSendMail: Yup.bool(),
      ...validationObject,
    });

    return validationSchema;
  };

  return useMemo(() => {
    if (
      !installerEntry ||
      !valuesFirstPage ||
      !valuesFirstPage.experts ||
      valuesFirstPage.experts.length === 0
    ) {
      return { initialValues: {}, validationSchema: Yup.object() };
    }
    const expertTriggers = calculateExpertTriggers(valuesFirstPage.experts, branchSelectionValues);
    const documentTriggerIds = calculateDocumentTriggers(installerEntry, branchSelectionValues);

    const initialValues = createInitialValues(
      valuesFirstPage.experts,
      documentTriggerIds,
      expertTriggers
    );

    const validationSchema = createValidationSchema(
      initialValues,
      installerEntry,
      expertTriggers,
      valuesFirstPage.experts,
      branchSelectionValues,
      documentTriggerIds
    );

    return {
      initialValues,
      validationSchema,
    };
  }, [valuesFirstPage, installerEntry, branchSelectionValues]);
};
