import { isRegisteringInstallerGuestOnly } from 'utils/InstallerUtils';
import { TENANT_ID } from 'global/constants';
import { getTenantId } from 'utils/tenantUtil';

export const NEFormIV = (valuesEmailInput, valuesBranchSelection, valuesForm) => ({
  companyName1: '',
  companyName2: '',
  companyName3: '',
  companyOwner: '',
  companyStreet: '',
  companyHouseNumber: '',
  companyPostal: '',
  companyCity: '',
  companyRegion: '',
  gridProviderEnergy: '',
  gridProviderGas: '',
  foreignCompanyIdEnergy: '',
  foreignCompanyIdGas: '',
  phone: '',
  mobile: '',
  energyAgreement: false,
  gasAgreement: false,
  craftsChamberArea: '',
  craftsChamberNumber: '',
  craftsChamberDate: '',
  businessRegistrationLocation: '',
  businessRegistrationDate: '',
  commercialRegisterNumber: '',
  commercialRegisterDate: '',
  doesNeedAvaGuestPostal:
    TENANT_ID.AVA === getTenantId() && isRegisteringInstallerGuestOnly(valuesBranchSelection),
  avaGuestPostal: '',
  experts: [],
  ...(valuesForm ? valuesForm : {}),
  ...valuesEmailInput,
});
