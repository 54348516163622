import { VALIDATION_CONDITIONS } from 'global/renderingValidationConstants';

export const evaluateCondition = (condition, variables) => {
  if (condition) {
    switch (condition.type) {
      case VALIDATION_CONDITIONS.AND:
        return condition.conditions.every((c) => evaluateCondition(c, variables));
      case VALIDATION_CONDITIONS.OR:
        return condition.conditions.some((c) => evaluateCondition(c, variables));
      case VALIDATION_CONDITIONS.NOT:
        return !evaluateCondition(condition.condition, variables);
      case VALIDATION_CONDITIONS.CONDITION:
        return condition.evaluate(variables);
      case VALIDATION_CONDITIONS.VALUE:
        return condition.evaluate();
      default:
        return false;
    }
  }
  return false;
};
