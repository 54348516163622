/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { useAuth } from 'context/Auth';
import { getTenantId } from 'utils/tenantUtil';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { isTokenExpired, logoutWithRedirect } from 'utils/AuthUtils';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { CustomModal } from 'components/CustomModal';

const Apollo = ({ children, isPublic }) => {
  const { token, isLoading, isCIAM } = useAuth();
  const [popupOpen, setPopupOpen] = useState(false);
  const tenantId = getTenantId();
  let uri;
  if (process.env.REACT_APP_GRAPHQL_ENDPOINT) {
    uri = `${process.env.REACT_APP_GRAPHQL_ENDPOINT}?tenant=${tenantId}`;
  }

  if (!uri) {
    // if this happens the env vars are not set correctly
    return <div>no uri</div>;
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }
  const jwtValidation = setContext((_, { headers }) => {
    if ((!token || isTokenExpired(token)) && !isPublic) {
      setPopupOpen(true);
      throw new Error('Invalid or missing token. Request cancelled.');
    }
    return {
      headers,
    };
  });

  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: uri,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : []),
    },
  });
  const link = ApolloLink.from([jwtValidation, httpLink]);

  const client = new ApolloClient({
    cache,
    link,
  });

  return (
    <ApolloProvider client={client}>
      <>
        {children}

        <CustomModal
          isOpen={popupOpen}
          title={'Login timeout.'}
          ctaButtons={
            <>
              <ActionButton
                onClick={() => {
                  setPopupOpen(false);
                  logoutWithRedirect(isCIAM);
                }}
                title="Ok"></ActionButton>
            </>
          }
          content={
            <> Bitte loggen Sie sich erneut ein. Ihre Session ist abgelaufen.</>
          }></CustomModal>
      </>
    </ApolloProvider>
  );
};

const PUBLIC_NO_AUTH_CONFIG = {
  context: {
    headers: {
      Authorization: '',
    },
  },
};

export { Apollo, PUBLIC_NO_AUTH_CONFIG };
